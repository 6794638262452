'use strict';

const SELECTOR_COUNTRY_POPUP = '#countries-popup';
const HEADER = '.header';
const PROMO_MESSAGE = '.promo-message-popup';
const CLOSE_COUNTRY_POPUP = '.countries-popup-close .icon-close';

function resizeCountryPopUp() {
    let headerHeight = $(HEADER).outerHeight();
    if ($(PROMO_MESSAGE).is(':visible') > 0) {
        headerHeight += $(PROMO_MESSAGE).outerHeight();
    }
    $(SELECTOR_COUNTRY_POPUP).css('top', headerHeight);
}

function managerCountrySidebar() {
    $('.country-sidebar').toggleClass('show');
    $('.country-sidebar-layer').toggleClass('show');
    $.stopScroll().toggle();
}

function managerCountryPopup() {
    $(SELECTOR_COUNTRY_POPUP).slideUp(800);
}

var countrySelectorManager = {
    init: function() {
        $(window).on('countryPopUp:resize header:resize', function() {
            resizeCountryPopUp();
        });

        var $countryPopup = $(SELECTOR_COUNTRY_POPUP);
        if ($countryPopup.length > 0) {
            $(SELECTOR_COUNTRY_POPUP).find('.country-link').on('click touchstart', this.countryClick);
            setTimeout(function() {
                $(SELECTOR_COUNTRY_POPUP).slideDown(function() {
                    $(window).trigger('countryPopUp:resize');
                });
            }, 5000);

            $(window).on('resize', function() {
                $(window).trigger('countryPopUp:resize');
            });
        }
    },

    countryClick: function(e) {
        if (typeof e === 'undefined') {
            return;
        }

        e.preventDefault();

        var $countryPopup = $(SELECTOR_COUNTRY_POPUP),
            $actionUrl = $(this).attr('data-href');

        $countryPopup.spinner().start();

        $.ajax({
            url: $actionUrl,
            type: 'GET',
            success: function(response) {
                response = JSON.parse(response);

                if (response.redirectLocale) {
                    var urlUtil = require('../util/url'),
                        localeParams = {
                            loc: response.redirectLocale,
                            code: response.redirectCode
                        };

                    window.location.href = urlUtil.appendParam(window.location.href, localeParams);
                }
            },
            complete: function() {
                $countryPopup.spinner().stop();
                $(SELECTOR_COUNTRY_POPUP).slideUp(800);
            }
        });
    },

    changeUrlSeo: function() {
        $('.countries .country a').on('click', function(e) {
            if ($(this).hasClass('nojs')) { return; }
            e.preventDefault();
            var dataHref = $(this).data('changehref');
            window.location.href = dataHref;
        });
    },

    manageCountryLayer: function() {
        $('.country-selector').on('touchstart click', function(e) {
            e.preventDefault();
            managerCountrySidebar();
        });

        $(document).on('touchstart click', '.country-sidebar-close', function(e) {
            e.preventDefault();
            managerCountrySidebar();
        });

        $(document).on('touchstart click', CLOSE_COUNTRY_POPUP, function(e) {
            e.preventDefault();
            managerCountryPopup();
        });

        $(document).on('touchstart click', '.country-sidebar-layer', function(e) {
            e.preventDefault();
            managerCountrySidebar();
        });
    }
};

module.exports = countrySelectorManager;
