'use strict';

var urlUtil = require('../util/url');

const MINICART_WRAPPER = '.minicart';
const MINICART_BODY = '.minicart .minicart-body';
const MINICART_HEADER_LINK = '.minicart-link';
const MINICART_CLOSE = '.minicart .layer-close';
const MINICART_CLASS_SHOW = 'show';
const MINICART_LAYER = '.left-layer';
const MINICART_QTY = '.minicart-count-value';
const MINICART_REMOVE_BUTTON = '.minicart .remove-product';

var miniCart = {};

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $(MINICART_BODY).append(errorHtml);
}

miniCart.removeItem = function() {
    $('body').off('click', MINICART_REMOVE_BUTTON);
    $('body').on('click', MINICART_REMOVE_BUTTON, function(e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = urlUtil.appendParam(url, urlParams);
        $(MINICART_WRAPPER).spinner().start();

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function(data) {
                if (data.basket.items.length === 0) {
                    miniCart.updateMinicart();
                } else {
                    $(window).trigger('cart:itemRemovedFromMinicart');
                }

                $(MINICART_QTY).empty().append(data.basket.numItems);
            },
            error: function(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                }
            }
        });
    });
};

miniCart.showMinicart = function() {
    $(MINICART_WRAPPER).addClass(MINICART_CLASS_SHOW);
    $(MINICART_LAYER).addClass(MINICART_CLASS_SHOW);
    $.stopScroll().enable();
};

miniCart.hideMinicart = function() {
    $(MINICART_WRAPPER).removeClass(MINICART_CLASS_SHOW);
    $(MINICART_LAYER).removeClass(MINICART_CLASS_SHOW);
    $.stopScroll().disable();

    setTimeout(function() {
        $(MINICART_WRAPPER).empty();
    }, 500);
};

miniCart.updateMinicart = function() {
    var url = $(MINICART_WRAPPER).data('action-url');

    if ($('.cart-page').length > 0) {
        return;
    }

    $(MINICART_WRAPPER).spinner().start();

    if ($(MINICART_WRAPPER + '.' + MINICART_CLASS_SHOW).length <= 0) {
        miniCart.showMinicart();
    }

    $.get(url, function(data) {
        $(MINICART_WRAPPER).empty();
        $(MINICART_WRAPPER).append(data);

        $(MINICART_WRAPPER).spinner().stop();
    });
};

miniCart.updateEmptyMinicart = function(data) {
    if (typeof data === 'undefined') {
        return;
    }

    if ($('.cart-page').length > 0) {
        $.spinner().start();
        location.reload();
    }

    $(window).trigger('cart:minicartEmpty', data);
};

miniCart.manageMinicartCountUpdate = function(response) {
    if (typeof response === 'undefined') {
        return;
    }
    if (response.error) {
        return;
    }

    $(MINICART_QTY).empty().append(response.quantityTotal);
    $(MINICART_HEADER_LINK).trigger('click');
};

miniCart.manageMinicartLayer = function() {
    $(MINICART_HEADER_LINK).on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        miniCart.updateMinicart();
    });

    $(document).on('click', MINICART_CLOSE, function() {
        miniCart.hideMinicart();
    });

    $(document).on('click', MINICART_LAYER, function() {
        miniCart.hideMinicart();
    });

    $(window).on('cart:minicartEmpty', function() {
        miniCart.updateEmptyMinicart();
    });
    $(window).on('cart:itemRemovedFromMinicart', function() {
        miniCart.updateMinicart();
    });

    $(MINICART_WRAPPER).on('count:update', function(e, data) {
        miniCart.manageMinicartCountUpdate(data);
    });
};

module.exports = function() {
    miniCart.manageMinicartLayer();
    miniCart.removeItem();
};
