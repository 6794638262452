'use strict';

const base = require('base/components/footer');
var footer = {};

const creditsAnchor = '.credits-dianacorp';
const creditsOver = '.copyright-over';

footer.init = function() {
    // calling like this because base
    // exports the component as a function and not as an object

    base();
};

/**
 * Additional click events
 */
footer.initCreditsDianacorp = function() {
    $(document.body).on('click', function() {
        if($(creditsAnchor).hasClass('opened')) {
            $(creditsAnchor).removeClass('opened');
            $(creditsOver).fadeOut();
        }
    });
    $(creditsAnchor).on('click', function(e) {
        e.stopPropagation();
        if($(this).hasClass('opened')) {
            $(this).removeClass('opened');
            $(creditsOver).fadeOut();
        }else{
            $(this).addClass('opened');
            $('.copyright-over').fadeIn();
        }
    });
};

module.exports = footer;
