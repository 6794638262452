var cookies = {

    /**
     * Set cookie by name, value and days
     *
     * @param {String} name - The name of the cookie.
     * @param {Boolean} value - The value of the cookie.
     * @param {Number} days - Number of days until cookie expires..
     */
    set: function(name, value, days) {
        var domain = window.location.hostname;

        var expiration = new Date();
        expiration.setTime(expiration.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = 'expires=' + expiration.toUTCString();
        document.cookie = name + '=' + value + '; ' + expires + '; ' + domain + ';path=/';
    },

    /**
     * Get a cookie by name, from the all cookies.
     *
     * @param {String} name - The name of the cookie.
     * @returns {String} - The value of the cookies, or `null`.
     */
    get: function(name) {
        var key = name + '=';
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(key) === 0) {
                return cookie.substring(key.length, cookie.length);
            }
        }

        return null;
    }
};

module.exports = cookies;
