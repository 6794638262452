'use strict';

const productDomUpdate = {};

productDomUpdate.configs = {
    pricesPrice: '.prices .price',
    promotions: '.promotions',
    mainAttributes: '.main-attributes',
    productDetail: '.product-detail',
    swatchAttributeContainer: '.swatch-attribute-container',
    unavailable: '.unavailable',
    addToCart: '.add-to-cart',
    addToCartGlobal: 'button.add-to-cart, button.add-to-cart-global',
    productName: '.product-name',
    productSku: '.product-number',
    restockableClass: 'is-restockable',
    notRestockableClass: 'is-not-restockable',
    selectedClass: 'selected',
    hiddenClass: 'hidden',
    urlProp: 'data-url',
    disabledClass: 'disabled',
    carouselItem: '.primary-images .carousel-inner .carousel-item',
    pid: '.add-to-cart[data-pid]',
    iconWishlist: 'icon-wishlist',
    iconWishlistFull: 'icon-wishlist-black'
};

/**
* Generates html for product attributes section
*
* @param {array} attributes - list of attributes
* @return {string} - Compiled HTML
*/
productDomUpdate.getAttributesHtml = function(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function(attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function(attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                   + attribute.value + '</div>';
            });
        }
    });
    return html;
};

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * This function had to be copied due to MFRA idiocy -
 * the original function is called by another private function, which had to be modified
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
productDomUpdate.processSwatchValues = function(attr, $productContainer) {
    attr.values.forEach(function(attrValue) {
        productDomUpdate.domChange($productContainer, attr, attrValue);
    });
};

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * This function had to be copied due to MFRA idiocy -
 * the original function is called by another private function, which had to be modified
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
productDomUpdate.processNonSwatchValues = function(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function(attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url)
            .removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
};

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * This function had to be copied and altered due to MFRA idiocy -
 * the original function has a hardcoded private array of swatchable attribute IDs,
 * so the behaviour cannot be changed otherwise
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 */
productDomUpdate.updateAttrs = function(attrs, $productContainer) {
    attrs.forEach(function(attr) {
        if (attr.swatchable) {
            productDomUpdate.processSwatchValues(attr, $productContainer);
        } else {
            productDomUpdate.processNonSwatchValues(attr, $productContainer);
        }
    });
};

productDomUpdate.updateMainAttributes = function($productContainer, response) {
    // Update attributes
    let attributes = $productContainer.find(productDomUpdate.configs.mainAttributes).length;
    if (attributes > 0) {
        $productContainer.find(productDomUpdate.configs.mainAttributes).empty().html(productDomUpdate.getAttributesHtml(response.product.attributes));
    }
};

productDomUpdate.updatePrices = function($productContainer, response) {
    // Update pricing
    var $priceSelector = $(productDomUpdate.configs.pricesPrice, $productContainer).length ? $(productDomUpdate.configs.pricesPrice, $productContainer) : $(productDomUpdate.configs.pricesPrice);
    $priceSelector.replaceWith(response.product.price.html);
};

productDomUpdate.updatePromo = function($productContainer, response) {
    // Update promotions
    $productContainer.find(productDomUpdate.configs.promotions).empty().html(response.product.promotionsHtml);
};

productDomUpdate.enableCart = function($productContainer, response) {
    // Enable "Add to Cart" button if all required attributes have been selected
    $(productDomUpdate.configs.addToCartGlobal, $productContainer).trigger('product:updateAddToCart', {
        product: response.product, $productContainer: $productContainer
    }).trigger('product:statusUpdate', response.product);
};

productDomUpdate.updateImages = function(imageUrls, $productContainer) {
    var slideCount = $(productDomUpdate.configs.carouselItem, $productContainer).length;
    if (slideCount > imageUrls.productImage.length) {
        var imageCount = imageUrls.productImage.length + 1;
        for (let i = imageCount; i <= slideCount; i++) {
            if ($(productDomUpdate.configs.carouselItem, $productContainer).eq(($(productDomUpdate.configs.carouselItem, $productContainer).length) - 1).hasClass('active')) {
                $(productDomUpdate.configs.arouselItem, $productContainer).eq(0).addClass('active');
            }
            $(productDomUpdate.configs.carouselItem, $productContainer).eq(($(productDomUpdate.configs.carouselItem, $productContainer).length) - 1).remove();
        }
    } else if (slideCount < imageUrls.productImage.length) {
        var carouselDiff = imageUrls.productImage.length - slideCount;
        for (let i = 0; i < carouselDiff; i++) {
            var newItem = $(productDomUpdate.configs.carouselItem, $productContainer).eq(0).clone().removeClass('active');
            newItem.find('img').attr('data-image-index', imageUrls.productImage.length - (carouselDiff - i));
            newItem.appendTo($('.carousel-inner', $productContainer));
        }
    }

    var $images = $productContainer.find('[data-image-index]');
    var srcSet = {};

    $images.each(function() {
        var dataIndex = $(this).data('image-index');
        if (typeof imageUrls.length === 'undefined') {
            let images = imageUrls.productImage;
            let imagesRetina = imageUrls.productImage2x;
            let imagesZoom = imageUrls.original;

            if (typeof imagesRetina[dataIndex] !== 'undefined' && typeof images[dataIndex] !== 'undefined') {
                srcSet.retina = imagesRetina[dataIndex].url;
                srcSet.normal = images[dataIndex].url;
                srcSet.zoom = imagesZoom[dataIndex].url;

                srcSet.srcSet = srcSet.normal + ' 1x, ' + srcSet.retina + ' 2x';
                $productContainer.find('[data-image-index="' + dataIndex + '"]').attr('src', srcSet.normal);

                $productContainer.find('[data-image-index="' + dataIndex + '"]').attr('srcset', srcSet.srcSet);

                $productContainer.find('[data-image-index="' + dataIndex + '"]').attr('data-src', srcSet.normal);

                $productContainer.find('[data-image-index="' + dataIndex + '"]').attr('data-srcset', srcSet.srcSet);

                $productContainer.find('[data-image-index="' + dataIndex + '"]').attr('data-zoom', srcSet.zoom);
            }
        } else {
            srcSet.normal = imageUrls[dataIndex];
            $productContainer.find('[data-image-index="' + dataIndex + '"]').attr('src', srcSet.normal);
            $productContainer.find('[data-image-index="' + dataIndex + '"]').attr('data-src', srcSet.normal);
            $productContainer.find('[data-image-index="' + dataIndex + '"]').attr('data-zoom', srcSet.zoom);
        }
    });
};

productDomUpdate.updateTitleAndDesc = function($productContainer, response) {
    $productContainer.find(productDomUpdate.configs.productName).empty().html(response.product.productName);
    $productContainer.find(productDomUpdate.configs.productSku).empty().html(response.product.id);
    $productContainer.find(productDomUpdate.configs.pid).attr('data-pid', response.product.id);
};


productDomUpdate.updateWishlist = function($productContainer, response) {
    if (response.isInWishlist === true) {
        if ($('.' + productDomUpdate.configs.iconWishlist, $productContainer).length >= 1) {
            $('.' + productDomUpdate.configs.iconWishlist, $productContainer).removeClass(productDomUpdate.configs.iconWishlist).addClass(productDomUpdate.configs.iconWishlistFull);
        }
    } else {
        if ($('.' + productDomUpdate.configs.iconWishlistFull, $productContainer).length >= 1) {
            $('.' + productDomUpdate.configs.iconWishlistFull, $productContainer).removeClass(productDomUpdate.configs.iconWishlistFull).addClass(productDomUpdate.configs.iconWishlist);
        }
    }
};

/**
* Replace the current Url with the newUrl
* @param {string} newUrl - new url to replace
*/
productDomUpdate.replaceCurrentUrl = function(newUrl) {
    window.history.replaceState(newUrl, $(document).prop('title'), newUrl);
};

/**
 * Get the new url after attribute select, includes the params defined in the listQueryStringParams custom preferences
 * @param {string} queryStringAttributeSelect - query string atrribute select
 * @returns {string} - the new Url
 */
productDomUpdate.getUrlAfterAttributeSelect = function(queryStringAttributeSelect) {
    var currentUrlOrigin = window.location.origin;
    return (currentUrlOrigin + queryStringAttributeSelect);
};

productDomUpdate.domChange = function($productContainer, attr, attrValue) {
    var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]');
    var $swatchAnchor = $attrValue.parent();
    var $swatchContainer = $swatchAnchor.parent();

    $swatchAnchor.removeClass(productDomUpdate.configs.restockableClass);
    if (attrValue.available === false && attrValue.isRestockable) {
        $swatchAnchor.addClass(productDomUpdate.configs.restockableClass);
    }

    $swatchAnchor.removeClass(productDomUpdate.configs.notRestockableClass);
    $swatchAnchor.removeClass(productDomUpdate.configs.disabledClass);
    if (attrValue.available === false && !attrValue.isRestockable) {
        $swatchAnchor.addClass(productDomUpdate.configs.notRestockableClass);
        $swatchAnchor.addClass(productDomUpdate.configs.disabledClass);
    }

    if (attrValue.selected) {
        $swatchAnchor.addClass(productDomUpdate.configs.selectedClass);
    } else {
        $swatchAnchor.removeClass(productDomUpdate.configs.selectedClass);
    }

    if (attrValue.hidden) {
        $swatchAnchor.addClass(productDomUpdate.configs.hiddenClass);
        $swatchContainer.addClass(productDomUpdate.configs.hiddenClass);
    } else {
        $swatchAnchor.removeClass(productDomUpdate.configs.hiddenClass);
        $swatchContainer.removeClass(productDomUpdate.configs.hiddenClass);
    }


    if (attrValue.url) {
        $swatchAnchor.data('url', attrValue.url);
    } else {
        $swatchAnchor.removeAttr(productDomUpdate.configs.urlProp);
    }

    // Disable if not selectable
    $swatchAnchor.removeClass('selectable unselectable');

    $swatchAnchor.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
};

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
productDomUpdate.updateAvailability = function(response, $productContainer) {
    if (typeof response === 'undefined' || typeof $productContainer === 'undefined') {
        return;
    }

    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<div>' + response.resources.info_selectforstock + '</div>';
    } else {
        availabilityMessages.forEach(function(message) {
            availabilityValue += '<div>' + message + '</div>';
        });

        $('.prices-add-to-cart-actions', $productContainer).slideDown();
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
};

module.exports = productDomUpdate;
