'use strict';

function isMobile() {
    if ($('.is-mobile').length > 0) {
        if ($('.is-mobile').css('display') === 'block') {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

function isTablet() {
    if ($('.is-tablet').length > 0) {
        if ($('.is-tablet').css('display') === 'block') {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

function isTabletLanscape() {
    if ($('.is-tablet-landscape').length > 0) {
        if ($('.is-tablet-landscape').css('display') === 'block') {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

function isInViewPort(el, allVisible, offset) {
    // CHECK IF IS JQUERY OR SELECTOR
    if (!(el instanceof jQuery)) {
        el = $(el);
    }
    if (typeof el === 'undefined') {
        return;
    }
    if (typeof el.offset() === 'undefined') {
        return;
    }
    if (typeof offset === 'undefined') {
        offset = 0;
    }
    let top = $(window).scrollTop();
    let footer = top + $(window).height();
    let posTopEl = el.offset().top + offset;
    let elementBottom = posTopEl + el.height();

    if (allVisible === true) {
        return top < posTopEl && footer > elementBottom;
    } else {
        return posTopEl <= footer && elementBottom >= top;
    }
}

function supportTouch() {
    return 'ontouchstart' in window || navigator.msMaxTouchPoints;
}

module.exports = {
    isMobile: isMobile,
    isTablet: isTablet,
    isTabletLanscape: isTabletLanscape,
    isInViewPort: isInViewPort,
    supportTouch: supportTouch
};
