'use strict';

var COOKIES = require('../cookies');
var CLOSE_BUTTON = '.close-button';
var POPUP_HIDDEN = 'popup-hidden';

var PROMO_MESSAGE_COOKIE = 'promo_message_closed';
var PROMO_MESSAGE_POPUP = $('.promo-message-popup');
var PROMO_MESSAGE_POPUP_CONTENT = $('.promo-message-popup .promomessage-carousel-container');

var popupsManager = {
    init: function(popup, cookieName, duration) {
        if (typeof popup === 'undefined' || typeof cookieName === 'undefined' || typeof duration === 'undefined') {
            return;
        }

        if (!popupsManager.hasCookie(cookieName)) {
            popupsManager.showPopup(popup);
        }

        popup.find(CLOSE_BUTTON).on('tap click', function() {
            popupsManager.hidePopup(popup);
            popupsManager.setCookies(cookieName, duration);
        });

        $(window).trigger('header:resize');
    },

    /**
     * Show the headerPromo popup.
     *
     * @param {Object} popup - jQuery object, holding the popup content
     */
    showPopup: function(popup) {
        if (typeof popup === 'undefined' || ($.trim((PROMO_MESSAGE_POPUP_CONTENT).html()).length === 0)) {
            return;
        }

        popup.removeClass(POPUP_HIDDEN);
        $(window).trigger('header:resize');
    },

    /**
     * Check for existing cookies
     *
     * @param {string} cookieName - The name of the popup's cookie
     * @return {boolean} True if the cookie exists.
     */
    hasCookie: function(cookieName) {
        if (typeof cookieName === 'undefined') {
            return;
        }

        return Boolean(COOKIES.get(cookieName));
    },

    /**
     * Set a cookie if headerPromo close button is clicked.
     *
     * @param {string} cookieName - The name of the popup's cookie
     * @param {Number} duration - Expiration cookie time in minutes
     */
    setCookies: function(cookieName, duration) {
        if (typeof cookieName === 'undefined' || typeof duration === 'undefined') {
            return;
        }

        COOKIES.set(cookieName, true, duration);
    },

    /**
     * Hide the headerPromo popup.
     *
     * @param {Object} popup - jQuery object, holding the popup content
     */
    hidePopup: function(popup) {
        if (typeof popup === 'undefined') {
            return;
        }

        popup.addClass(POPUP_HIDDEN);
        $(window).trigger('header:resize');
    },

    promoMessage: function() {
        popupsManager.init(PROMO_MESSAGE_POPUP, PROMO_MESSAGE_COOKIE, 30);
    },
};


module.exports = popupsManager;
