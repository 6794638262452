'use strict';
require('flatpickr');
require('flatpickr/dist/l10n/it.js');
require('flatpickr/dist/l10n/de.js');
require('flatpickr/dist/l10n/es.js');
require('flatpickr/dist/l10n/ja.js');
require('flatpickr/dist/l10n/fr.js');

var SELECTOR_CHECKBOX_LABEL = '.form-check-label';
var SELECTOR_CHECKBOX = '.form-check-input ';
var CHECKED_CLASS = SELECTOR_CHECKBOX_LABEL.substr(1) + '__checked';
var SELECTOR_PASSWORD_SHOW = '.form-password-view';

function handleCheckboxStatus(checkbox) {
    var siblings = $(SELECTOR_CHECKBOX.trim() + '[name="' + checkbox.attr('name') + '"]');

    siblings.each(function() {
        var label = $(this).closest(SELECTOR_CHECKBOX_LABEL);

        if (label.length) {
            label.toggleClass(CHECKED_CLASS, $(this)[0].checked);
        }
    });
}

function applyCustomElementStyles(checkbox) {
    var currentElementLabel = $(SELECTOR_CHECKBOX.trim() + '[name="' + checkbox.attr('name') + '"]');
    currentElementLabel.each(function() {
        var label = $(this).closest(SELECTOR_CHECKBOX_LABEL);
        label.addClass(checkbox.attr('type'));
    });
}

function managePassword() {
    $(document).on('click', SELECTOR_PASSWORD_SHOW, function(e) {
        e.preventDefault();
        var input = $(this).parent().find('input');
        $(this).toggleClass('form-password-hide');
        var textTemp = $(this).title;
        $(this).attr('title', $(this).attr('data-change-status'));
        $(this).attr('data-change-status', textTemp);
        if(input.attr('type') === 'text') {
            input.get(0).type = 'password';
        } else {
            if(input.attr('type') === 'password') {
                input.get(0).type = 'text';
            }
        }
    });
}

function handleDate() {
    var selector = $('.custom-datepicker');

    if(!selector.length === 0) {
        return;
    }

    var locale = $('html').attr('lang'),
        minDate,
        maxDate,
        formatDate = selector.attr('format') ? selector.attr('format') : 'd/m/Y',
        options = {
            allowInput: true,
            dateFormat: formatDate,
            locale: locale,
            onOpen: function(selectedDates, dateStr, instance) {
                $(instance.element).prop('readonly', true);
            },
            onClose: function(selectedDates, dateStr, instance) {
                $(instance.element).prop('readonly', false);
            },
            onChange: function(selectedDates, dateStr, instance) {
                if(selectedDates.length === 0) {
                    $(instance.element).addClass('is-invalid');

                    if($(instance.mobileInput)) {
                        $(instance.mobileInput).addClass('is-invalid');
                    }

                    if(instance.config.maxDate && instance.config.minDate) {
                        var ddMax = instance.config.minDate.getDate();
                        var mmMax = instance.config.minDate.getMonth() + 1;
                        var yyyyMax = instance.config.minDate.getFullYear();

                        var dd = instance.config.maxDate.getDate();
                        var mm = instance.config.maxDate.getMonth() + 1;
                        var yyyy = instance.config.maxDate.getFullYear();
                        var dataError = $(instance.element).data('range-error');
                        var error = dataError + ddMax + '/' + mmMax + '/' + yyyyMax + ' - ' + dd + '/' + mm + '/' + yyyy;

                        $(instance.element).siblings('.invalid-feedback').html(error);
                    }
                } else {
                    $(instance.element).removeClass('is-invalid');
                    if($(instance.mobileInput)) {
                        $(instance.mobileInput).removeClass('is-invalid');
                    }
                }
            }
        };

    if(selector.data('birthday')) {
        var today = new Date();
        maxDate = new Date();
        maxDate.setFullYear(today.getFullYear() - 18);
        minDate = new Date();
        minDate.setFullYear(today.getFullYear() - 100);
        options.maxDate = maxDate;
        options.minDate = minDate;
    }

    $('.custom-datepicker').flatpickr(options);
}


module.exports = {
    createCheckboxToggleListener: function() {
        $(document).on('click', SELECTOR_CHECKBOX_LABEL, function() {
            var checkbox = $(this).find(SELECTOR_CHECKBOX);

            if (checkbox.length) {
                handleCheckboxStatus(checkbox);
            }
        });

        // Handle state changes that occur as a result of an AJAX call,
        // e.g. in the shipping methods list on the shipping page
        $(document).on('ajaxComplete', this.handleCheckboxStates);
    },

    handleCheckboxStates: function() {
        $(SELECTOR_CHECKBOX).each(function() {
            handleCheckboxStatus($(this));
            applyCustomElementStyles($(this));
        });
    },

    init: function() {
        managePassword();
        handleDate();
    }
};
