window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function() {
    processInclude(require('./components/forms'));
    processInclude(require('./components/menu'));
    processInclude(require('./components/footer'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/fayt'));
    processInclude(require('./components/popups'));
    processInclude(require('./components/newsletter'));
    processInclude(require('./components/categoryNavigation'));
});

processInclude(require('./product/helper/addToCart'));
processInclude(require('./components/quickBuy'));
processInclude(require('./product/wishlist'));

require('./thirdParty/bootstrap');
require('./thirdParty/lazyLoad');
require('base/components/spinner');
require('lightgallery');
require('lg-fullscreen');
require('lg-thumbnail');
require('lg-zoom');
require('./util/stopScroll');
