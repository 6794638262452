'use strict';
const productDomUpdate = require('./helper/productDomUpdate');

const handleVariation = {};


/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
handleVariation.handleVariantResponse = function(response, $productContainer) {
    if (typeof response === 'undefined' || typeof $productContainer === 'undefined') {
        return;
    }

    if (response.product.variationAttributes) {
        productDomUpdate.updateAttrs(response.product.variationAttributes, $productContainer);
    }

    var imageUrls;

    if (typeof response.product.disImages !== undefined) {
        imageUrls = response.product.disImages.hiRes1;
    } else {
        imageUrls = response.product.images['hi-res-1'];
    }

    productDomUpdate.updateAvailability(response, $productContainer);

    productDomUpdate.updateImages(imageUrls, $productContainer);

    productDomUpdate.updatePrices($productContainer, response);

    productDomUpdate.updatePromo($productContainer, response);

    productDomUpdate.enableCart($productContainer, response);

    productDomUpdate.updateMainAttributes($productContainer, response);

    productDomUpdate.updateTitleAndDesc($productContainer, response);

    productDomUpdate.updateWishlist($productContainer, response);

    // UPDATE URL ONLY IF REQUEST IS FROM MAIN ATTRIBUTE
    $('body').on('product:afterAttributeSelect', function(e, response) {
        var newUrl = productDomUpdate.getUrlAfterAttributeSelect(response.data.product.selectedProductUrl);
        productDomUpdate.replaceCurrentUrl(newUrl);
    });
};

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
handleVariation.attributeSelect = function(selectedValueUrl, $productContainer) {
    if (typeof selectedValueUrl === 'undefined' || typeof $productContainer === 'undefined') {
        return;
    }

    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect', { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function(data) {
                // PRODUCTS OPTIONS REMOVED
                handleVariation.handleVariantResponse(data, $productContainer);
                $('body').trigger('product:afterAttributeSelect', { data: data, container: $productContainer });
            },
            complete: function() {
                $.spinner().stop();
            }
        });
    }
};

/**
 * updates the quick view when a product size is selected
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
handleVariation.sizeSelect = function(selectedValueUrl, $productContainer) {
    if (typeof selectedValueUrl === 'undefined' || typeof $productContainer === 'undefined') {
        return;
    }

    if (selectedValueUrl) {
        $('body').trigger('quickBuy:beforeAttributeSelect', { url: selectedValueUrl, container: $productContainer });

        $productContainer.spinner().start();

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function(data) {
                // PRODUCTS OPTIONS REMOVED
                handleVariation.handleVariantResponse(data, $productContainer);
                $('body').trigger('quickBuy:afterAttributeSelect', { data: data, container: $productContainer });
            },
            complete: function() {
                $.spinner().stop();
            }
        });
    }
};

module.exports = handleVariation;
