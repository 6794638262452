'use strict';

/**
 * @description Exposes utility functions form working with URLs
 */

var urlUtils = {

    /**
     * appends params to a url
     *
     * @param {string} url - Original url
     * @param {Object} params - Parameters to append
     * @returns {string} result url with appended parameters
    */
    appendParam: function(url, params) {
        var newUrl = url.split('?')[0] + '?';
        var currentParams = this.getParamValues(url);

        params = $.extend({}, currentParams, params);

        newUrl += Object.keys(params).map(function(key) {
            return key + '=' + encodeURIComponent(params[key]);
        }).join('&');

        return newUrl;
    },

    /**
     * Retrieves the value of the requested GET parameter from the provided URL
     *
     * @param {string} url - Original url
     * @param {string} param - Parameter whose value to get
     * @return {string} The parameter value, if available
    */
    getParamValue: function(url, param) {
        return this.getParamValues(url)[param];
    },

    /**
     * Retrieves the values of all GET parameters from the provided URL
     *
     * @param {string} url - Original url
     * @return {object} Hash containing the values of all URL parameters
    */
    getParamValues: function(url) {
        var params = {};
        var qs = url.split('?')[1];

        if (!qs) {
            return params;
        }

        var paramPairs = qs.split('&');

        if (paramPairs.length > 0) {
            for (var i = 0; i < paramPairs.length; i++) {
                var paramEls = paramPairs[i].split('=');

                if (paramEls.length === 2) {
                    params[paramEls[0]] = paramEls[1];
                }
            }
        }
        return params;
    },

    /**
     * Checks if the required URL has the requested parameter
     *
     * @param {string} url - Original url
     * @param {string} param - The parameter name to check for
     * @return {boolean} - True if the parameter is present
     */
    hasParam: function(url, param) {
        var qs = url.split('?')[1];

        if (qs) {
            var paramSearch = encodeURIComponent(param) + '=';

            return qs.indexOf(paramSearch) >= 0;
        }

        return false;
    },

    /**
     * appends fragment QS to a url
     *
     * @param {string} url - Original url
     * @param {Object} params - Parameters to append
     * @returns {string} result url with appended parameters
     */
    appendFragment: function(url, params) {
        var newUrl = url.split('#')[0] + '#';
        var currentParams = this.getFragmentValues(url);

        params = $.extend({}, currentParams, params);

        newUrl += Object.keys(params).map(function(key) {
            return key + '=' + encodeURIComponent(params[key]);
        }).join('&');

        return newUrl;
    },

    /**
     * Checks if the required URL has the requested fragment parameter
     *
     * @param {string} url - Original url
     * @param {string} param - The parameter name to check for
     * @return {boolean} - True if the parameter is present
     */
    hasFragment: function(url, param) {
        var qs = url.split('#')[1];

        if (qs) {
            var paramSearch = encodeURIComponent(param) + '=';
            return qs.indexOf(paramSearch) >= 0;
        }

        return false;
    },

    /**
     * Retrieves the value of the requested fragment parameter from the provided URL
     *
     * @param {string} url - Original url
     * @param {string} param - Parameter whose value to get
     * @return {string} The parameter value, if available
     */
    getFragmentValue: function(url, param) {
        return this.getFragmentValues(url)[param];
    },

    /**
     * Retrieves the values of all fragment parameters from the provided URL
     *
     * @param {string} url - Original url
     * @return {object} Hash containing the values of all URL parameters
     */
    getFragmentValues: function(url) {
        var params = {};
        var qs = url.split('#')[1];

        if (!qs) {
            return params;
        }

        var paramPairs = qs.split('&');

        if (paramPairs.length > 0) {
            for (var i = 0; i < paramPairs.length; i++) {
                var paramEls = paramPairs[i].split('=');

                if (paramEls.length === 2) {
                    params[paramEls[0]] = paramEls[1];
                }
            }
        }

        return params;
    },

    /**
     * converts fragments to QS in a specified URL
     *
     * @param {string} url - Original url
     * @returns {string} result url with parameters
    */
    convertFragmentsToQuerystring: function(url) {
        var newUrl = url,
            currentParams,
            currentFragments;

        if (typeof newUrl === 'undefined') {
            return null;
        }

        if (newUrl.indexOf('?') > -1) {
            currentParams = this.getParamValues(newUrl);
            currentFragments = this.getFragmentValues(newUrl);
            newUrl = newUrl.split('?')[0] + '?';

            newUrl = this.appendParam(newUrl, currentParams);
            newUrl = this.appendParam(newUrl, currentFragments);
        } else {
            newUrl = newUrl.replace('#', '?');
        }

        return newUrl;
    }
};

module.exports = urlUtils;
