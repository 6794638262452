'use strict';

var addToWishlistClass = '.add-to-wish-list';
var gotoWishlistClass = '.goto-wishlist';
var wishlistMessageClass = '.wishlist-message';
var addToCartBtn = '.prices-add-to-cart-actions .add-to-cart';
var stockNotification = '.show-stock-notification';
var productInfos = '.product-infos';

var wishlist = {};

wishlist.manageAddtowishlistAnimations = function() {
    $(document).on('mouseenter', addToWishlistClass, function() {
        var context = $(this).closest(productInfos);
        if ($(addToCartBtn, context).attr('disabled') && !($(stockNotification, context).length > 0)) {
            var error = $(this).find('.error-copy');
            error.stop().fadeIn();
        }
    });

    $(document).on('mouseleave', addToWishlistClass, function() {
        var context = $(this).closest(productInfos);
        if ($(addToCartBtn, context).attr('disabled') && !($(stockNotification, context).length > 0)) {
            var error = $(this).find('.error-copy');
            error.stop().fadeOut();
        }
    });
};

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {string} context - The context
 * @param {Object} button - button that was clicked to add a product to the wishlist
 */
function displayMessage(data, context) {
    if(typeof data === 'undefined') {
        return;
    }
    var msg = data.msg;
    if (data.error) {
        msg = '<span class="error" >' + msg + '</span>';
        $(gotoWishlistClass, context).fadeOut();
    } else {
        $(gotoWishlistClass, context).fadeIn();
    }
    $(wishlistMessageClass, context).html(msg).fadeIn();

    setTimeout(function() {
        $(gotoWishlistClass, context).fadeOut();
        $(wishlistMessageClass, context).fadeOut();
    }, 10000);
}

wishlist.addToWishlist = function() {
    $(document).on('click', addToWishlistClass, function(e) {
        e.preventDefault();
        var context = $(this).closest(productInfos);
        if ($(addToCartBtn, context).attr('disabled') && !($(stockNotification, context).length > 0)) {
            return;
        }
        var url = $(this).data('href');
        var pid = context.find('.product-id').html();
        var optionId = context.find('.product-option').attr('data-option-id');
        var optionVal = context.find('.options-select option:selected').attr('data-value-id');
        optionId = optionId || null;
        optionVal = optionVal || null;

        $.spinner().start();

        $(this).attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                pid: pid,
                optionId: optionId,
                optionVal: optionVal
            },
            success: function(data) {
                displayMessage(data, context);
                $('.link-wishlist .icon-wishlist', context).removeClass('icon-wishlist').addClass('icon-wishlist-black');
                $.spinner().stop();
            },
            error: function(err) {
                displayMessage(err, context);
                $.spinner().stop();
            }
        });
    });
};

module.exports = wishlist;
