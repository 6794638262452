'use strict';

const quickBuy = {};
const handleVariantion = require('../product/handleVariation');
const urlUtil = require('../util/url');

const QUICKBUY_MODAL = '#quickBuyModal',
    OPEN_QUICKBUY_BUTTON = '.openQuickBuyButton',
    swatchAttributeContainer = QUICKBUY_MODAL + ' .swatch-attribute-container',
    addToCart = QUICKBUY_MODAL + ' .add-to-cart',
    hasQuickBuy = '.hasQuickBuy',
    qbLink = '.pid-link-quickBuy',
    unavailable = QUICKBUY_MODAL + ' .unavailable';

// Handle the quick buy opening
quickBuy.init = function() {
    $(document).on('click', OPEN_QUICKBUY_BUTTON, function() {
        $('body').trigger('removeStockNotificationForm');

        var productID = $(this).closest('.product').attr('data-pid');
        var url = $(this).data('action-url');
        var enableStockNotification = $(this).data('enableStockNotification');

        if(typeof productID === 'undefined' || typeof url === 'undefined') {
            return;
        }

        $.spinner().start();

        $.ajax({
            url: url,
            data: { pid: productID, enableStockNotification: enableStockNotification},
            method: 'GET',
            success: function(data) {
                if ($(QUICKBUY_MODAL).length > 0) {
                    $(QUICKBUY_MODAL).replaceWith(data);
                } else {
                    $('body').append(data);
                }
                let carousel = $(QUICKBUY_MODAL).find('.carousel');
                $(carousel).carousel('dispose');
                $(carousel).carousel();
                $(QUICKBUY_MODAL).modal('show');
            },
            complete: function() {
                $.spinner().stop();
            }
        });
    });

    $(document).on('click', hasQuickBuy + ' a', function(e) {
        e.preventDefault();
        e.stopPropagation();
        $(OPEN_QUICKBUY_BUTTON, $(this).closest('.product')).trigger('click');
    });

    $(document).on('show.bs.modal', QUICKBUY_MODAL, function() {
        $.stopScroll().enable();
    });

    $(document).on('hide.bs.modal', QUICKBUY_MODAL, function() {
        $.stopScroll().disable();
    });
};

quickBuy.swatchableAttributes = function() {
    $(document).on('click', swatchAttributeContainer, function(e) {
        e.stopPropagation();
        e.preventDefault();
        let hrefValue = $(this).data('url');
        if ($(this).attr('disabled') || $(this).hasClass('disabled')) {
            return;
        }

        var $productContainer = $(this).closest(QUICKBUY_MODAL);

        if ($(this).children(unavailable).length > 0) {
            $(addToCart).prop('disabled', true);
        } else {
            $(addToCart).prop('disabled', false);
        }

        hrefValue = urlUtil.convertFragmentsToQuerystring(hrefValue);

        handleVariantion.sizeSelect(hrefValue, $productContainer);
    });
};

quickBuy.handleSpecialDomUpdate = function() {
    $('body').on('quickBuy:afterAttributeSelect', function(e, response) {
        $(qbLink).attr('href', response.data.product.url);
    });
};

quickBuy.addToCart = function() {
    $(document).on('click', addToCart, function() {
        $(QUICKBUY_MODAL).spinner().start();
        $('body').trigger('product:addToCart', { data: this });
    });

    $('body').on('product:afterAddToCart', function() {
        $(QUICKBUY_MODAL).modal('hide');
    });
};

module.exports = quickBuy;
