'use strict';
const $body = $('body');
let scrollPosition = 0;

$.stopScroll = function() {
    var Fn = function() {
        this.enable = function() {
            scrollPosition = window.pageYOffset;
            $body.addClass('stop-scroll');
            $body.css({
                overflow: 'hidden',
                position: 'fixed',
                top: scrollPosition * -1 + 'px',
                width: '100%'
            });
        };
        this.disable = function() {
            $body.removeClass('stop-scroll');
            $body.css({
                overflow: '',
                position: '',
                top: '',
                width: ''
            });
            window.scrollTo(0, scrollPosition);
        };
        this.toggle = function() {
            if($body.hasClass('stop-scroll')) {
                this.disable();
            } else {
                this.enable();
            }
        };
    };
    return new Fn();
};
