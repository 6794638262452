'use strict';
const debounce = require('lodash/debounce');

const FAYT_WRAPPER = '.fayt';
const FAYT_HEADER_LINK = '.fayt-link';
const FAYT_CLOSE = '.layer-close';
const FAYT_CLASS_SHOW = 'show';
const FAYT_LAYER = '.left-layer';
const FORM = FAYT_WRAPPER + ' form';
const SUGGESTED = '.suggested';
const SEARCH_FIELD = FAYT_WRAPPER + ' input.search-field';
const SUBMIT = FAYT_WRAPPER + ' .btn';
const FORM_PLACEHOLDER = FORM + 'input:placeholder';

var fayt = {};

var wrapper = '.suggestions-wrapper';
var endpoint = $(wrapper).data('url');
var minChars = 3;

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response -
 * Empty object literal if null response or string with rendered
 * suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = $(wrapper).empty();

    $(FAYT_WRAPPER).spinner().stop();

    if (!(typeof (response) === 'object')) {
        var sugg = $(response).find(SUGGESTED).html();
        fakePlaceholder(sugg);

        $suggestionsWrapper.append(response).show();

        $(window).trigger('fayt:complete');
    } else {
        $suggestionsWrapper.hide();
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        $(FAYT_WRAPPER).spinner().start();
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: processResponse,
            error: function() {
                $(FAYT_WRAPPER).spinner().stop();
            }
        });
    }
}

function fakePlaceholder(sugg) {
    if(sugg) {
        var serched = $(SEARCH_FIELD).val();
        if(sugg.indexOf(serched) !== -1) {
            $(FORM).attr('data-placeholder', sugg);
            $(FORM_PLACEHOLDER).css('color', 'black');
        } else {
            $(FORM).removeAttr('data-placeholder');
            $(FORM_PLACEHOLDER).css('color', 'transparent');
        }
    } else {
        $(FORM).removeAttr('data-placeholder');
        $(FORM_PLACEHOLDER).css('color', 'transparent');
    }
}

fayt.searchOnClick = function() {
    $(SEARCH_FIELD).each(function() {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on('keydown', function(e) {
            $(FORM).addClass('hide-placeholder');
            if(e.which === 13 && ($(FORM).attr('data-placeholder') !== undefined)) {
                e.preventDefault();
                $(this).val($(FORM).attr('data-placeholder'));
                $(FORM_PLACEHOLDER).css('color', 'black');
                debounceSuggestions(this, e);
            } else {
                fakePlaceholder();
                debounceSuggestions(this, e);
            }
        });
    });
};

function show() {
    $(FAYT_WRAPPER).addClass(FAYT_CLASS_SHOW);
    $(FAYT_LAYER).addClass(FAYT_CLASS_SHOW);
    $.stopScroll().enable();
}

function hide() {
    $(FAYT_WRAPPER).removeClass(FAYT_CLASS_SHOW);
    $(FAYT_LAYER).removeClass(FAYT_CLASS_SHOW);
    $.stopScroll().disable();
}

fayt.manageLayer = function() {
    $(FAYT_HEADER_LINK).on('touchstart click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        show();
    });

    $(document).on('click', FAYT_CLOSE, function() {
        hide();
    });

    $(document).on('touchstart click', FAYT_LAYER, function() {
        hide();
    });

    $(document).on('click', SUBMIT, function() {
        $(FORM).submit();
    });
};

module.exports = fayt;
