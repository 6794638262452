'use strict';

const addToCart = {};
const base = require('base/product/base');


addToCart.add = function() {
    $('body').on('product:addToCart', function(e, context) {
        var $context = $(context.data);
        var addToCartUrl;
        var pid;

        $('body').trigger('product:beforeAddToCart', this);

        pid = $context.attr('data-pid');

        addToCartUrl = $('.add-to-cart-url').val();

        var form = {
            pid: pid,
            childProducts: [],
            quantity: 1
        };

        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function(data) {
                    if(data.error) {
                        $('.add-to-cart-error').html(data.message).slideDown();
                        setTimeout(function() {
                            $('.add-to-cart-error').slideUp();
                        }, 5000);
                    }
                    $('body').trigger('product:afterAddToCart', data);
                    $('.minicart').trigger('count:update', data);
                    base.miniCartReportingUrl(data.reportingURL);
                },
                complete: function() {
                    $.spinner().stop();
                }
            });
        }
    });
};


module.exports = addToCart;
