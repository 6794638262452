/* global grecaptcha */

'use strict';

var COOKIES = require('../cookies');
var countriesList = require('../../../../../../int_cpcompany_sc/cartridge/config/countriesList.json');

const NEWSLETTER_OPTIN = ($('#newsletterLandingPageForm').length > 0) ? '#newsletterLandingPageForm' : '.newsletterOptinModal';
console.log('NEWS - START', NEWSLETTER_OPTIN);

var newsletterPopup = {};
const NEWSLETTER_FORM = '.newsletter-optin-form';
const NEWSLETTER_FOOTER_INPUT = '#footerNewsletter .email-footer';
const NEWSLETTER_FOOTER_SUBMIT = '#footerNewsletter .submit-footer';
const FORM_RECAPTCHA = NEWSLETTER_FORM + ' #newsletter-recaptcha';
const CONTACT_RECAPTCHA = '#form-recaptcha';
const COUNTRY = '#country';
let popupOpened = false

newsletterPopup.manageFooter = function() {
    $('#footerNewsletter').on('submit', function(e) {
        e.preventDefault();
    });

    $(NEWSLETTER_FOOTER_SUBMIT).on('click', function() {
        if($(this).parents('form')[0].checkValidity()) {
            $(NEWSLETTER_OPTIN).modal('show');
            $(NEWSLETTER_FORM).find('#email').val($(NEWSLETTER_FOOTER_INPUT).val());
        }
    });
};

newsletterPopup.manageCaptchaPopup = function() {
    $(NEWSLETTER_OPTIN).on('shown.bs.modal', function() {
        if ($('.contactus-form-wrapper').length > 0 || $('.register-page').length > 0) {
            window.widgetRecaptcha = undefined;
            $(CONTACT_RECAPTCHA).empty();
        }
        var siteKey = $(FORM_RECAPTCHA).data('sitekey');
        $(FORM_RECAPTCHA).remove();
        $('<div id="newsletter-recaptcha"></div>').appendTo(NEWSLETTER_FORM);
        $(FORM_RECAPTCHA).data('sitekey', siteKey);
        manageCaptcha();
    });

    $(NEWSLETTER_OPTIN).on('hidden.bs.modal', function() {
        window.widgetRecaptcha = undefined;
        $(FORM_RECAPTCHA).empty();
    });
};

newsletterPopup.manageCaptchaNewsletterLanding = function() {
    if ($('#newsletterLandingPageForm').length > 0) {
        manageCaptcha();
    }
};

newsletterPopup.setCountry = function() {
    if ($(COUNTRY).length > 0) {
        var currentCountry = $(COUNTRY).data('current');
        if(currentCountry){

            countriesList.forEach(country => {
                if(country.code == currentCountry){
                    $(COUNTRY).val(country.name)
                }
            });
        }
    }
};

function manageCaptcha() {
    setTimeout(() => {
        console.log('NEWS - MANAGE CAPTCHA');
        var widgetRecaptcha;
        
        window.grecaptcha.ready(function() {
            if($(FORM_RECAPTCHA).length > 0) {
                if (typeof window.widgetRecaptcha !== 'undefined') {
                    grecaptcha.reset(window.widgetRecaptcha);
                } else {
                    widgetRecaptcha = grecaptcha.render('newsletter-recaptcha', {
                        sitekey: $(FORM_RECAPTCHA).data('sitekey'),
                        size: 'invisible',
                        callback: function() {
                            var hasError = false;
                            if($('.custom-checkbox .custom-control-input:checked', NEWSLETTER_FORM).length < 1) {
                                $('.custom-checkbox', NEWSLETTER_FORM).addClass('is-invalid');
                                hasError = true;
                            }
                            var isChecked = false;
                            $('.custom-radio .form-check-input', NEWSLETTER_FORM).each(function(i, obj) {
                                if(obj.checked === true) {
                                    isChecked = true;
                                }
                            });
                            if(!isChecked) {
                                $('.custom-radio', NEWSLETTER_FORM).addClass('is-invalid');
                                hasError = true;
                            }
    
                            if(hasError) {
                                $(window).trigger('submitnewsletter:error');
                                return;
                            }
                            $(NEWSLETTER_FORM).trigger('submit:captcha');
                            console.log('NEWS - TRIGGER SUBMIT');
    
                        }
                    });
    
                    window.widgetRecaptcha = widgetRecaptcha;
                }
            }
        });
    
        $(window).on('submitnewsletter:error', function() {
            if (typeof grecaptcha !== 'undefined') {
                grecaptcha.reset(window.widgetRecaptcha);
            }
        });
    }, 1000);

}

/**
 * Manages auto opt in newsletter popup if enabled
 * @returns {void}
 */
newsletterPopup.managerAutoNlOptin = function() {
    if ($(NEWSLETTER_OPTIN).length <= 0) {
        return;
    }

    // Set cookies if user subscribes to the newsletter through the landing page
    if (NEWSLETTER_OPTIN === '#newsletterLandingPageForm') {
        $(document).on('submit', NEWSLETTER_FORM, function() {
            COOKIES.set('newslettermodalpromo', 'viewed', 90);
        });
    }

    if (!COOKIES.get('newslettermodalpromo') && $(NEWSLETTER_OPTIN).data('auto')) {
        $(window).on('scroll.newsletterpopup', function() {
            if ($(window).scrollTop() > 300 && !popupOpened) {
                popupOpened = true
                if(!$(NEWSLETTER_OPTIN).hasClass('show')) {
                    $(NEWSLETTER_OPTIN).modal('show');
                    $(window).off('scroll.newsletterpopup');
                    $(NEWSLETTER_OPTIN).trigger('newsletter:newsletterOptinModalShow');
                }
            }
        });

        $(NEWSLETTER_OPTIN).on('hidden.bs.modal', function() {
            COOKIES.set('newslettermodalpromo', 'viewed', 90);
        });
    } else {
        if($(NEWSLETTER_OPTIN).data('force-open') === true) {
            $(NEWSLETTER_OPTIN).modal('show');
        }
    }
};

newsletterPopup.manageNewsletterPopupSubmit = function() {
    $(document).on('submit:captcha', NEWSLETTER_FORM, function(e) {
        e.preventDefault();

        var urlRecaptcha = $(NEWSLETTER_FORM)[0].action;
        $.ajax({
            url: urlRecaptcha,
            type: 'POST',
            data: $(NEWSLETTER_FORM).serialize(),
            dataType: 'json',
            success: function(data) {
                if(data.success) {
                    $(NEWSLETTER_FORM)[0].action = data.actionUrl;

                    var html = $('<input type="hidden" name="oid" value="' + data.originId + '" />');
                    $(NEWSLETTER_FORM)[0].append(html[0]);

                    if($(NEWSLETTER_FORM)[0].checkValidity()) {
                        $(NEWSLETTER_OPTIN).modal('hide');
                    }
                    console.log('FORM TO SUBMIT');
                    $(NEWSLETTER_FORM).submit();
                    console.log($(NEWSLETTER_FORM));
                } else {
                    $('.error-message-captcha').addClass('alert alert-danger').html(data.errorMsg);
                }
            }
        });
    });
};
module.exports = newsletterPopup;
