'use strict';
/* global grecaptcha */

const base = require('base/components/clientSideValidation');

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
base.functions.clearForm = function(form) {
    $(form).find('.is-invalid').removeClass('is-invalid');
};

function invalidaForm() {
    $('form input, form select, form textarea').on('invalid', function(e) {
        e.preventDefault();
        this.setCustomValidity('');
        if (!this.validity.valid) {
            var validationMessage = this.validationMessage;
            $(this).addClass('is-invalid');
            if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                validationMessage = $(this).data('pattern-mismatch');
            }
            if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                && $(this).data('range-error')) {
                validationMessage = $(this).data('range-error');
            }
            if ((this.validity.tooLong || this.validity.tooShort)
                && $(this).data('range-error')) {
                validationMessage = $(this).data('range-error');
            }
            if (this.validity.valueMissing && $(this).data('missing-error')) {
                validationMessage = $(this).data('missing-error');
            }
            $(this).parents('.form-group').find('.invalid-feedback')
                .text(validationMessage);
        }
    });
}

function validateForm(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $.each(this, function() {
            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                $(this).addClass('is-invalid');
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                $(this).parents('.form-group').find('.invalid-feedback').text(validationMessage);
            }
        });
        $(this).find('input, select, textarea').each(function() {
            if (!this.validity.valid) {
                $(this).trigger('invalid', this.validity);
            }
        });
    }
    return valid;
}

base.submit = function() {
    $(document).on('submit', 'form', function(e) {
        var valid;

        if ($(this).attr('novalidate')) {
            valid = true;
        } else {
            valid = validateForm.call(this, e);
        }

        if (typeof window.widgetRecaptcha !== 'undefined' && valid && $(this).find('.grecaptcha-badge').length !== 0) {
            var response = grecaptcha.getResponse(window.widgetRecaptcha);
            if (response.length === 0) {
                e.preventDefault();
                grecaptcha.execute(window.widgetRecaptcha);
                return false;
            }
        }
        return valid;
    });
};

base.buttonClick = function() {
    $(document).on('click', 'form button[type="submit"], form input[type="submit"]', function() {
        base.functions.clearForm($(this).parents('form'));
    });
};

base.invalid = function() {
    invalidaForm();

    $(document).on('register:invalid:event', function() {
        invalidaForm();
    });
};

base.functions.invalidaForm = invalidaForm;

module.exports = base;
