'use strict';

/**
 * If the user navigates PLP->PDP->PLP, scroll to the previously selected product.
 *
 * When the user selects a product in PLP, create 2 sessionStorage elements, to save the actual PLP and the selected product.
 * If the user navigates to a different page which is not the PDP of the selected product, remove the two sessionStorage elements.
 * If the users navigate to the previous PLP, scroll to the previously selected product and remove the two sessionStorage elements.
 */

const SEARCH_RESULT_HEADER = '.search-results-header';
const CATEGORY_ID = '.category-id';
const PRODUCT_TILE = '.product';
const PRODUCT_DETAIL_WRAPPER = '.product-detail.product-wrapper';

var categoryNavigation = {
    init: function() {
        if ('sessionStorage' in window) {
            // User in PLP
            if ($(SEARCH_RESULT_HEADER).length > 0) {
                // User in correct PLP
                if (sessionStorage['targetProduct'] && sessionStorage['targetPLP'] && sessionStorage['targetPLP'] === $(CATEGORY_ID).val()) {
                    var targetProduct = sessionStorage['targetProduct'];
                    if ($('.product[data-pid="' + targetProduct + '"]').length > 0) {   // Product is still present in PLP
                        var targetProductTile = $('.product[data-pid="' + targetProduct + '"]');
                        $('html, body').animate({
                            scrollTop: targetProductTile.offset().top - 150
                        });

                        setTimeout(function() {
                            cleanStorage();
                        }, 2000);
                    }
                } else {    // User in wrong PLP
                    cleanStorage();
                }

                // Create 2 sessionStorage elements
                $(document).on('click', PRODUCT_TILE, function(e) {
                    sessionStorage['targetProduct'] = $(this).attr('data-pid');
                    sessionStorage['targetPLP'] = $(CATEGORY_ID).val();
                });
            } else if ($(PRODUCT_DETAIL_WRAPPER).length > 0) {    // User in PDP
                // User in wrong PDP
                if ($(PRODUCT_DETAIL_WRAPPER).attr('data-pid') !== sessionStorage['targetProduct']) {
                    cleanStorage();
                }
            } else {  // User not in PDP or PLP
                cleanStorage();
            }
        }
    }
};

function cleanStorage() {
    sessionStorage.removeItem('targetProduct');
    sessionStorage.removeItem('targetPLP');
}

module.exports = categoryNavigation;
