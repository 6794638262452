'use strict';

const queryUtils = require('../util/queryUtils.js');

const TOGGLER = '.navbar-toggler';
const NAV = 'header';
const DROPDOWN = '.dropdown-menu';
const DROPDOWN_ITEM = '.dropdown';
const DROPDOWN_CONTENT = '.dropdown .dropdown-container *';
const DROPDOWN_TABLET_ITEM = '.dropdown > .nav-link.dropdown-toggle';
const DROPDOWN_MOBILE = '[data-mobile-dropdown="dropdown"]';
const FIRST_LEVEL_TOGGLE = '.ul-shop [data-toggle="dropdown"]';
const TOGGABLE = $(TOGGLER).data('toggable-target');
const MENU_MOBILE = '.menu-toggleable-left';
const CLOSE = '.menu-container .close-button';
const BACK = '.back-to';
const DROPDOWN_ICON = '.icon-arrow-down';
const OVERLAY = '#sg-navbar-collapse';
const MAIN = '#maincontent';
const BANNER = '.experience-dianaComponents-heroBanner';
const COUNTRY_POPUP = '#countries-popup';
const MENU_MOBILE_FOOTER = '.menu-mobile-footer';
const NAV_TAG = 'nav';
const MENU_IMAGE = '.menu-image';
const FIRST_ITEM = '.ul-shop > .nav-item';
const SHOP_MENU = '.shop-menu';
const NAV_LINK = '.nav-link';
const PROMO_MESSAGE = '.promo-message-popup';
const CLOSEMENU = '.close-menu';
const OPENED = '.opened';
const DROPDOWN_LI = '.dropdown-item';

var menu = {};

var scrollBackground = function() {
    $(window).scroll(function() {
        if ($(document).scrollTop() > 8) {
            $(NAV_TAG).addClass('scrolled');
        } else {
            $(NAV_TAG).removeClass('scrolled');
        }
    });
};

var overflowItem = function() {
    var maxWidth = $(SHOP_MENU).width() / 6;

    $(FIRST_ITEM).each(function() {
        if($(this).width() > maxWidth) {
            $(this).find(NAV_LINK).addClass('limited');
            $(this).find(NAV_LINK).css('max-width', maxWidth);
        }
    });
};

var calculateMenuPosition = function() {
    if(!queryUtils.isMobile() && !queryUtils.isTabletLanscape()) {
        // calculate top position from navbar height
        var countryHeight = $(COUNTRY_POPUP).length ? $(COUNTRY_POPUP).outerHeight() : 0;
        var promoMessageHeight = $(PROMO_MESSAGE).length ? $(PROMO_MESSAGE).outerHeight() : 0;
        var navHeight = $(NAV).outerHeight() + countryHeight + promoMessageHeight;
        var headerHeight = $(NAV).outerHeight();

        // set dropdown top position
        $(DROPDOWN).css({ top: headerHeight });

        if($(BANNER).length > 0) {
            $(MAIN).css({ 'margin-top': -navHeight });
        }
    }
};

var initDesktop = function() {
    if(!queryUtils.isMobile() && !queryUtils.isTabletLanscape()) {
        var timeOut;

        $(DROPDOWN_ITEM).off('mouseenter').on('mouseenter', function() {
            window.clearTimeout(timeOut);

            if($(DROPDOWN_ITEM).hasClass('show') && !$(this).hasClass('show')) {
                $(DROPDOWN_ITEM).removeClass('show');
            }

            if(!$(this).hasClass('show')) {
                $(DROPDOWN_ITEM).find(DROPDOWN).fadeOut(200);
            }

            $(this).addClass('show');
            $(this).find(DROPDOWN).fadeIn(200);
        });

        $(DROPDOWN_ITEM).off('mouseleave').on('mouseleave', function() {
            timeOut = window.setTimeout(function() {
                $(DROPDOWN_ITEM).removeClass('show');
                $(DROPDOWN_ITEM).find(DROPDOWN).fadeOut(200);
            }, 300);
        });

        $(DROPDOWN_CONTENT).off('click').on('click', function() {
            $(this).parents().find(DROPDOWN).fadeOut(200);
        });

        scrollBackground();

        $('a[data-img]').hover(function() {
            var container = $(this).parents('.dropdown-container');
            var imageBanner = container.find(MENU_IMAGE);
            if(imageBanner.length === 0) {
                return;
            }

            if(typeof $(MENU_IMAGE).data('default-image') === 'undefined') {
                var defaultImg = imageBanner.attr('src');
                $(MENU_IMAGE).data('default-image', defaultImg);
            }

            var img = $(this).data('img');

            if(img) {
                imageBanner.attr('src', img);
            } else {
                img = imageBanner.data('default-image');
                imageBanner.attr('src', img);
            }
        });

        overflowItem();
    }
};

var initTabletLandscape = function() {
    if(queryUtils.isTabletLanscape()) {
        $(DROPDOWN_CONTENT).off('click').on('click', function() {
            $(DROPDOWN_ITEM).removeClass('show');
        });
        scrollBackground();
    }
};

var closeMobileMenu = function() {
    $(MENU_MOBILE).removeClass('in');
    $(MENU_MOBILE).attr('aria-hidden', 'true');
    $(OPENED).removeClass('opened');
    $(DROPDOWN).removeClass('show');
    $(DROPDOWN_MOBILE).siblings('ul').removeClass('show');
    $(DROPDOWN_MOBILE).children(DROPDOWN_ICON).removeClass('rotate');
    $(BACK).addClass('invisible');
};

var initMobile = function() {
    if(queryUtils.isMobile() && !queryUtils.isTabletLanscape()) {
        $(TOGGLER).off('click').on('click', function(e) {
            e.preventDefault();
            $(TOGGABLE).addClass('in');
            $(TOGGABLE).attr('aria-hidden', 'false');
            $.stopScroll().enable();
        });

        $(CLOSE).off('click touchstart').on('click touchstart', function(e) {
            e.stopPropagation();
            e.preventDefault();
            $.stopScroll().toggle();
            closeMobileMenu();
        });

        $(FIRST_LEVEL_TOGGLE).off('click').on('click', function() {
            $(this).parents('.ul-shop').addClass('opened');
        });

        $(BACK).off('click').on('click', function(e) {
            e.preventDefault();
            $(OPENED).removeClass('opened');
            $(DROPDOWN).removeClass('show');
            $(DROPDOWN_MOBILE).siblings('ul').removeClass('show');
            $(DROPDOWN_MOBILE).children(DROPDOWN_ICON).removeClass('rotate');
            $(BACK).addClass('invisible');
        });

        $(DROPDOWN_MOBILE).off().on('click', function(e) {
            e.stopPropagation();
            if($(this).children(DROPDOWN_ICON).length !== 0) {
                e.preventDefault();
                $(this).siblings('ul').toggleClass('show');
                $(this).children(DROPDOWN_ICON).toggleClass('rotate');
                $(this).siblings().find('.dropdown-link').on('click', function(e) {
                    e.stopPropagation();
                });
            }
        });

        $(DROPDOWN_TABLET_ITEM).on('click', function() {
            $(BACK).removeClass('invisible');
            $(DROPDOWN).css({ top: 0 });
        });

        $(OVERLAY).off('click').on('click', function(e) {
            if(e.target === e.currentTarget) {
                $.stopScroll().toggle();
                closeMobileMenu();
            }
        });
    }
};

menu.init = function() {
    // prevent bubbling from dropdown.js
    $(CLOSEMENU).on('click', function(e) {
        e.stopPropagation();
    });

    $(MENU_MOBILE_FOOTER).on('click', function(e) {
        e.stopPropagation();
    });

    $(DROPDOWN_LI).on('click', function(e) {
        e.stopPropagation();
    });

    $(window).on('header:resize countryPopUp:resize', function() {
        calculateMenuPosition();
    });

    initDesktop();
    initTabletLandscape();
    initMobile();

    $(window).on('resize', function() {
        calculateMenuPosition();
        initDesktop();
        initTabletLandscape();
        initMobile();
    });
};

module.exports = menu;
